@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

    .ping{
    animation: ping 1.75s cubic-bezier(0, 0, 0.2, 1) infinite;
    }

    @keyframes ping {
        75%, 100% {
            transform: scale(2);
            opacity: 0;
        }
    }

    .sombra{
        box-shadow: 0px 0px 20px 5px rgb(254 186 13 / 0.5);
    }
   
}